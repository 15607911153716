//查询我的商铺申请状态
const getBusinessCircleStatusUrl = `/serveApi/miniapi/trading/checkStatus`;
//获取我的商铺信息
const getMyBusinessUrl = `/serveApi/miniapi/trading/getMyMallShop`;
//提交商圈申请
const postApplyUrl = `/serveApi/miniapi/trading/apply`;
//个人信息
const userInfoUrl = `/miniApi/user/mini/user-info`;
//证件类型下拉框列表查询
const getInitInfoUrl = `/sysApi/userManagement/getInitInfo`;
//订单列表
const getOrderListUrl = `/serveApi/miniapi/trading/getGoodsOrderInfo`;
//订单详情
const getOrderIdUrl = `/serveApi/miniapi/trading/getGoodsOrderDetailsById`;
// 获取全部商户信息
const getShopInfoListUrl = `/serveApi/manageapi/pointsMallShop/getShopInfoList`;
// 根据商圈id获取详情
const detailByIdUrl = `/serveApi/manageapi/pointsMallShop/detailById`;
// 获取商品列表
const getShopGoodsListUrl = ` /serveApi/miniapi/pointsMall/list`;
//获取区级联动接口
const areaLinkUrl = "/spaceApi/space/getSpaceLinkList";
export {
  getBusinessCircleStatusUrl,
  getMyBusinessUrl,
  postApplyUrl,
  userInfoUrl,
  getInitInfoUrl,
  getOrderListUrl,
  getOrderIdUrl,
  getShopInfoListUrl,
  detailByIdUrl,
  getShopGoodsListUrl,
  areaLinkUrl,
};
